import InitialState from "./InitialState";

const GlobalReducer = (state = InitialState, action) => {
  switch (action.type) {
    case "fetchPalmGrovesStart":
      return {
        ...state,
        palmGroves: { ...state.palmGroves, isLoading: true },
      };
    case "fetchPalmGrovesSuccess":
      return {
        ...state,
        palmGroves: {
          ...state.palmGroves,
          isLoading: false,
          payload: action.payload,
        },
      };
    case "fetchPalmGrovesError":
      return {
        ...state,
        palmGroves: {
          ...state.palmGroves,
          isLoading: false,
          error: action.payload,
        },
      };
      case "fetchLeadsStart":
        return {
          ...state,
          leads: { ...state.leads, isLoading: true },
        };
      case "fetchLeadsSuccess":
        return {
          ...state,
          leads: {
            ...state.leads,
            isLoading: false,
            payload: action.payload,
          },
        };
      case "fetchLeadsError":
        return {
          ...state,
          leads: {
            ...state.leads,
            isLoading: false,
            error: action.payload,
          },
        };
      case "fetchStatusStart":
        return {
          ...state,
          status: { ...state.status, isLoading: true },
        };
      case "fetchStatusSuccess":
        return {
          ...state,
          status: {
            ...state.status,
            isLoading: false,
            payload: action.payload,
          },
        };
      case "fetchStatusError":
        return {
          ...state,
          status: {
            ...state.status,
            isLoading: false,
            error: action.payload,
          },
        };
      case "fetchLdpSearchStart":
        return {
          ...state,
          leads: { ...state.ldpSearch, isLoading: true },
        };
      case "fetchLdpSearchSuccess":
        return {
          ...state,
          leads: {
            ...state.ldpSearch,
            isLoading: false,
            payload: action.payload,
          },
        };
      case "fetchLdpSearchError":
        return {
          ...state,
          leads: {
            ...state.ldpSearch,
            isLoading: false,
            error: action.payload,
          },
        };
    default:
      return state;
  }
};

export default GlobalReducer;
